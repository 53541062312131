.rejigg-image-gallery .image-gallery-slides {
  display: flex;
  min-height: 400px;
  max-height: 800px;
}

.pulsate {
  -webkit-animation: pulsate 1.5s ease-out;
  -webkit-animation-iteration-count: infinite;
  opacity: 0.5;
}

@keyframes pulsate {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1.0;
  }
  100% {
    opacity: 0.5;
  }
}

.pointer {
  cursor: pointer
}

iframe {
  border: none;
  width: 100%;
}

a {
  color: inherit;
}
